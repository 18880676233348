import {
  TMP_TO_REMOVE_BL_API_URL,
  TMP_TO_REMOVE_BL_API_TOKEN,
  BLID,
} from "../conf/conf";

export async function getBusinessLocator() {
  return await getBusinessLocatorData(BLID);
}

export default async function getBusinesses(bl) {
  return await getFeatures(bl.collectionId);
}

async function getBusinessLocatorData(blid) {
  const response = await fetch(
    `${TMP_TO_REMOVE_BL_API_URL}/businesslocators/${blid}/versions/latest?access-token=${TMP_TO_REMOVE_BL_API_TOKEN}`
  );
  return await response.json();
}

async function getFeatures(collectionId) {
  const response = await fetch(
    `${TMP_TO_REMOVE_BL_API_URL}/collections/${collectionId}/features?access-token=${TMP_TO_REMOVE_BL_API_TOKEN}`
  );
  return await response.json();
}
