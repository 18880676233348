import "./SearchBar.scss";

import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { MapLibre } from "@jawg/places";

import addI18nContextConsumer from "../../i18n/addI18nContextConsumer";
import i18n from "./SearchBar.i18n.json";

import { REACT_APP_JAWG_PLACES_ACCESS_TOKEN } from "../../conf/conf";
import isDisplayedOnMobileScreen from "../../services/mobile";

function getUserPosition(cb) {
  if ("geolocation" in navigator) {
    /* la géolocalisation est disponible */
    navigator.geolocation.getCurrentPosition(function (position) {
      cb &&
        cb({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [position.coords.longitude, position.coords.latitude],
          },
        });
    });
  } else {
    /* la géolocalisation n'est pas disponible */
  }
}

function SearchBar({ map, onLocationSelected, strings }) {
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (!map) {
      return;
    }

    var customContainerPlaces = new MapLibre({
      accessToken: REACT_APP_JAWG_PLACES_ACCESS_TOKEN,
      searchOnTyping: true,
      showResultIcons: true,
      input: "#BusinessLocator-searchbar-input",
      marker: false,
      onClick: function (feature) {
        map._map[isDisplayedOnMobileScreen() ? "jumpTo" : "flyTo"]({
          center: feature.geometry.coordinates,
          zoom: 12,
        });
        onLocationSelected && onLocationSelected(feature);
      },
    });
    customContainerPlaces.attachMap(map._map);
  }, [map, onLocationSelected]);
  return (
    <div className="BusinessLocator-searchbar">
      <input
        id="BusinessLocator-searchbar-input"
        onChange={function (e) {
          setSearch(function () {
            return e.target.value;
          });
        }}
        placeholder={strings["placeholder"]}
        value={search}
      />
      <div id="BusinessLocator-searchbar-results" />
      <div
        className="BusinessLocator-searchbar-localisation-icon"
        onClick={() => {
          getUserPosition((feature) => {
            map._map[isDisplayedOnMobileScreen() ? "jumpTo" : "flyTo"]({
              center: feature.geometry.coordinates,
              zoom: 12,
            });
            onLocationSelected && onLocationSelected(feature);
          });
        }}
      >
        <img alt="geolocalise" src={`/images/gps.png`} />
      </div>
      <div className="BusinessLocator-searchbar-search-icon">
        <FontAwesomeIcon icon={faSearch} />
      </div>
    </div>
  );
}

export default addI18nContextConsumer(i18n)(SearchBar);
