import { getRoutingUrl, MODE_ROUTING } from "../conf/conf";

export function fetchRouting({
  departureFeature,
  arrivalFeature,
  selectedMode = MODE_ROUTING.CAR,
} = {}) {
  const from = coordinatesToObject(departureFeature);
  const to = coordinatesToObject(arrivalFeature);
  const url = `${getRoutingUrl(
    selectedMode,
    from,
    to
  )}&overview=full&steps=true&geometries=geojson`;
  return fetch(url, {
    method: "GET",
  }).then((response) => response.json());
}

function coordinatesToObject(feature) {
  const { coordinates } = feature.geometry;
  return {
    lng: coordinates[0],
    lat: coordinates[1],
  };
}
