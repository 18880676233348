import "./Header.scss";

import addI18nContextConsumer from "../../../i18n/addI18nContextConsumer";
import i18n from "./Header.i18n.json";

function Header({ strings }) {
  return (
    <header className="masthead">
      <div className="container">
        <div className="masthead-subheading">{strings["title"]}</div>
        <div className="masthead-heading text-uppercase">
          {strings["subtitle"]}
        </div>
      </div>
    </header>
  );
}

export default addI18nContextConsumer(i18n)(Header);
