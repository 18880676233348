export default function MarkerTo({
  markerColor,
  strokeMarkerColor,
  middleMarkerColor,
  pulseEffectMarkerColor,
}) {
  return `
  <div>
    <div
      style="background-color: ${markerColor};
        border-color: ${strokeMarkerColor};
        width: 30px;
        height: 30px;
        border-radius: 50% 50% 50% 0;
        transform: rotate(-45deg);
        margin: -36px 0 0 0;
        border-width: 2px;
        border-style: solid;
        display: flex;
        justify-content: center;
        align-items: center;"
    >
      <div
        style="background-color: ${middleMarkerColor};
        width: 12px;
        height: 12px;
        border-radius: 50%;"
      />
    </div>
    <div style="border-radius: 50%;
      height: 14px;
      width: 14px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -4px 0 0 -7px;
      transform: rotateX(55deg);
      z-index: -2;"
    >
      <div
        style="box-shadow: 0 0 1px 2px ${pulseEffectMarkerColor};
        border-radius: 50%;
        height: 40px;
        width: 40px;
        margin: -13px 0 0 -13px;
        animation: pulse-effect 1.5s ease-out;
        animation-iteration-count: infinite;
        opacity: 0;"
      />
    </div>
  </div>
`;
}
