import "./BusinessLocator.scss";

import { Component } from "react";

import getBusinesses, { getBusinessLocator } from "../../services/businesses";
import isDisplayedOnMobileScreen from "../../services/mobile";
import { fetchRouting } from "../../services/routing";
import i18nContext from "../../i18n/i18n.context";

import Map from "../map/Map";
import {
  drawMarker,
  drawAndGoToRouteLocation,
  MARKER_TYPE,
  buildIconImageExpression,
} from "../map/utils";
import Panel from "../panel/Panel";
import PanelMobile from "../panel-mobile/PanelMobile";
import { BL_LOCATION_LAYER_NAME } from "../../conf/conf";

export default class BusinessLocator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: props.language || navigator.language.substring(0, 2),
      map: void 0,
      businessesJSON: void 0,
      businessLocator: void 0,
      selectedBusiness: void 0,
      isPanelListVisible: true,
      location: void 0,
    };
    this.mapActive = true;
  }

  componentDidMount() {
    getBusinessLocator().then((businessLocator) => {
      getBusinesses(businessLocator).then((businessesJSON) => {
        this.setState({ businessesJSON, businessLocator });
      });
    });
  }

  shouldComponentUpdate() {
    return true;
  }

  getStyleComponent = () => {
    const { businessLocator } = this.state;
    return businessLocator.style;
  };

  callbackFeatureClicked = (featureId) => {
    const { businessesJSON } = this.state;
    const selectedBusiness = businessesJSON.features.find(
      (b) => b.id.toString() === featureId.toString()
    );
    selectedBusiness && this.setState({ selectedBusiness });
  };

  handleSelectBusiness = (selectedBusiness) => {
    this.setState({ selectedBusiness });
    this.highlightFeature(selectedBusiness);
  };

  highlightFeature = function (feature) {
    const { map } = this.state;
    //Highlight on map
    const featureId = Number.parseInt(feature.id);
    let zoom = Math.max(map.getZoom(), 12);
    map._map[isDisplayedOnMobileScreen() ? "jumpTo" : "flyTo"]({
      center: feature.geometry.coordinates,
      zoom: zoom,
    });
    map._map.setLayoutProperty(
      BL_LOCATION_LAYER_NAME,
      "icon-image",
      buildIconImageExpression(featureId, this.getStyleComponent())
    );
  };

  calculateItinary = (departureFeature, arrivalFeature) => {
    this.clearMap();
    this.setState({ gettingItinerary: true });
    fetchRouting({ departureFeature, arrivalFeature }).then((result) => {
      if (result.code === "Ok") {
        const routingTime = result.routes[0].duration;
        const routingDistance = result.routes[0].distance;
        const routingCoordinates = result.routes[0].geometry.coordinates;
        this.setState(
          {
            routingTime,
            routingDistance,
            routingCoordinates,
            gettingItinerary: false,
          },
          () => {
            const { map } = this.state;
            if (map) {
              this.drawRouting(map, departureFeature, arrivalFeature);
            }
          }
        );
      } else if (result.code === "NoRoute") {
        this.setState(
          {
            routingTime: 0,
            routingDistance: 0,
            routingCoordinates: void 0,
            gettingItinerary: false,
          },
          () => {
            const { map } = this.state;
            if (map) {
              this.clearMap(map);
            }
          }
        );
      }
    });
  };

  drawRouting = (map, departureFeature, arrivalFeature) => {
    const { routingCoordinates, openDetail } = this.state;
    const verySmallScreen = isDisplayedOnMobileScreen();
    let option = void 0;
    if (verySmallScreen) {
      this.setState({ openDetail: true });
      option = {
        padding: { top: 120, bottom: 240, right: 60, left: 60 },
      };
    } else if (openDetail) {
      option = {
        padding: { top: 120, bottom: 120, right: 120, left: 420 },
      };
    }
    const departureMarkerId = drawMarker(
      map,
      departureFeature,
      MARKER_TYPE.FROM
    );
    // const arrivalMarkerId = drawMarker(map, arrivalFeature, MARKER_TYPE.TO);
    const routingLineStringId = drawAndGoToRouteLocation(
      map,
      routingCoordinates,
      option
    );
    this.setState({
      departureMarkerId,
      // arrivalMarkerId,
      routingLineStringId,
      map,
    });
  };

  clearMap = () => {
    const { departureMarkerId, arrivalMarkerId, routingLineStringId, map } =
      this.state;
    if (map) {
      departureMarkerId && map.removeObject(departureMarkerId);
      arrivalMarkerId && map.removeObject(arrivalMarkerId);
      routingLineStringId && map.removeObject(routingLineStringId);
      this.setState({
        departureMarkerId: void 0,
        arrivalMarkerId: void 0,
        routingLineStringId: void 0,
      });
    }
  };

  addLocationPinToMap = (location) => {
    const { map } = this.state;
    this.clearMap();
    const departureMarkerId = drawMarker(map, location, MARKER_TYPE.TO);
    this.setState({ departureMarkerId });
  };

  handleSetLocation = (location) => {
    this.setState({ location });
    this.addLocationPinToMap(location);
  };

  handleShowItinerary = (business) => {
    const { location } = this.state;
    if (!business) {
      return;
    }

    if (isDisplayedOnMobileScreen()) {
      const [lng, lat] = business.geometry.coordinates;
      let url = `://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${lat},${lng}`;
      if (location) {
        const [lngOrigin, latOrigin] = location.geometry.coordinates;
        url += `&origin=${latOrigin}, ${lngOrigin}`;
      }
      // If it's an iPhone.
      if (
        navigator.platform.indexOf("iPhone") !== -1 ||
        navigator.platform.indexOf("iPod") !== -1 ||
        navigator.platform.indexOf("iPad") !== -1
      ) {
        window.open(`maps${url}`);
      } else {
        window.open(`https${url}`);
      }
    } else if (location) {
      this.setState({ isPanelListVisible: false });
      this.handleSelectBusiness(business);
      this.calculateItinary(location, business);
    }
  };

  render() {
    const {
      map,
      businessesJSON,
      selectedBusiness,
      isPanelListVisible,
      location,
      locale,
    } = this.state;
    return (
      <i18nContext.Provider value={{ locale }}>
        <div className="BusinessLocator-container">
          <Panel
            map={map}
            businesses={businessesJSON}
            selectedBusiness={selectedBusiness}
            setSelectedBusiness={this.handleSelectBusiness}
            isListVisible={isPanelListVisible}
            setIsListVisible={(isPanelListVisible) => {
              this.setState({ isPanelListVisible });
            }}
            setSelectedLocation={this.handleSetLocation}
            location={location}
            showItinerary={this.handleShowItinerary}
          />
          <PanelMobile
            map={map}
            business={selectedBusiness}
            hide={isPanelListVisible}
            location={location}
            showItinerary={this.handleShowItinerary}
          />
          <Map
            callbackFeatureClicked={this.callbackFeatureClicked}
            onInit={(map) => this.setState({ map })}
            getStyleComponent={this.getStyleComponent}
            getMapActive={() => this.mapActive}
            setMapActive={(mapActive) => {
              this.mapActive = mapActive;
            }}
          />
        </div>
      </i18nContext.Provider>
    );
  }
}
