import "./PanelMobile.scss";

import { useRef, useState } from "react";
import classNames from "classnames";
import { useSwipeable } from "react-swipeable";

import BusinessMobile from "../business-mobile/BusinessMobile";

import isDisplayedOnMobileScreen from "../../services/mobile";

export default function PanelMobile({
  business,
  hide,
  location,
  showItinerary,
}) {
  const [expand, setExpand] = useState(false);
  const containerRef = useRef(null);
  const handlers = useSwipeable({
    onSwipedDown: () => {
      const elementHTML = document.getElementById(
        "BusinessLocator-panel-mobile"
      );
      if (elementHTML && elementHTML.scrollTop === 0) {
        expand && setExpand(!expand);
      }
    },
    onSwipedUp: () => {
      !expand && setExpand(!expand);
    },
    preventDefaultTouchmoveEvent: true,
  });
  return (
    <div
      ref={containerRef}
      id="BusinessLocator-panel-mobile"
      className={classNames("BusinessLocator-panel-mobile", {
        "BusinessLocator-panel-mobile--expand": expand,
        "BusinessLocator-panel-mobile--hide": hide || !business,
      })}
      {...handlers}
    >
      <div className="BusinessLocator-panel-mobile-business">
        <hr className="BusinessLocator-panel-mobile-business-hr" />
        {business && (
          <BusinessMobile
            id={business.id}
            business={business.properties}
            setExpand={setExpand}
            expanded={expand}
            isItineraryCalculable={!!location || isDisplayedOnMobileScreen()}
            showItinerary={() => showItinerary(business)}
          />
        )}
      </div>
    </div>
  );
}
