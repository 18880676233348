import "./BusinessMobile.scss";

import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faDirections } from "@fortawesome/free-solid-svg-icons";

import addI18nContextConsumer from "../../i18n/addI18nContextConsumer";
import i18n from "./BusinessMobile.i18n.json";

const daysOfWeek = [
  "lundi",
  "mardi",
  "mercredi",
  "jeudi",
  "vendredi",
  "samedi",
  "dimanche",
];

function getOpenDayInfo(Timetables) {
  let day = (new Date().getDay() + 6) % 7;
  day = daysOfWeek[day];
  const timetableInfo = Timetables.find((t) => t.DayOfWeek === day);
  return {
    isOpen: !!timetableInfo,
    hours: timetableInfo && timetableInfo.OpeningPeriods,
  };
}

function getStatusText(hours, strings) {
  if (!hours) {
    return;
  }
  const splitedHours = hours.split(" - ");
  return `${strings["until"]} ${splitedHours[splitedHours.length - 1]}`;
}

function getOpenningHours(strings, openningHours) {
  if (!openningHours) {
    return strings["closed"];
  } else {
    if (openningHours.length < 16) {
      return openningHours;
    } else {
      let ohComponent = [];
      const regEx = /s*[0-9] [0-9]\s*/;
      let ohCopy = openningHours.slice();
      let findIndex;
      let i = 0;
      while (findIndex !== -1) {
        findIndex = ohCopy.search(regEx);
        if (findIndex > 0) {
          ohComponent.push(
            <div key={++i}>{ohCopy.slice(0, findIndex + 1)}</div>
          );
          ohCopy = ohCopy.slice(findIndex + 1);
        } else {
          ohComponent.push(<div key={++i}>{ohCopy}</div>);
        }
      }
      return ohComponent;
    }
  }
}

function BusinessMobile({
  id,
  business: {
    AddressLine: address,
    DistanceFromRef: distance,
    imgUrl,
    Name: name,
    Timetables,
    IsDrive,
    IsOpenDrive,
  },
  isItineraryCalculable,
  showItinerary,
  setExpand,
  strings,
}) {
  const { isOpen, hours } = getOpenDayInfo(Timetables);
  return (
    <div className="BusinessLocator-business-mobile">
      <div className="BusinessLocator-business-mobile-header">
        <div className="BusinessLocator-business-mobile-pic">
          <img
            alt="business pic"
            src={
              imgUrl ||
              `/images/tmp_business_pics/business${
                (Number.parseInt(id) % 5) + 1
              }.jpg`
            }
          />
        </div>
        <div className="BusinessLocator-business-mobile-info">
          <div className="BusinessLocator-business-mobile-id">
            <div className="BusinessLocator-business-mobile-name">{name}</div>
          </div>
          {distance && (
            <div className="BusinessLocator-business-mobile-distance">
              /&nbsp;
              {Number.isFinite(distance)
                ? distance > 1
                  ? `${distance.toFixed(2)}km`
                  : `${(distance * 1000).toFixed(0)}m`
                : ""}
            </div>
          )}

          <div className="BusinessLocator-business-mobile-status">
            <div
              className={classNames("BusinessLocator-business-mobile-isopen", {
                "BusinessLocator-business-mobile-isopen--opened": isOpen,
              })}
            ></div>
            <div className="BusinessLocator-business-mobile-status-text">
              <span
                className={
                  isOpen
                    ? "BusinessLocator-business-mobile-status-text-opened"
                    : "BusinessLocator-business-mobile-status-text-closed"
                }
              >
                {isOpen ? strings["opened"] : strings["closed"]}
              </span>
              {getStatusText(hours, strings)}
            </div>
          </div>
        </div>
      </div>

      <div className="BusinessLocator-business-mobile-body">
        <div className="BusinessLocator-business-mobile-address">
          <div className="BusinessLocator-business-mobile-address1">
            {address &&
              address.split("\\n").map((a, i) => <div key={i}>{a}</div>)}
          </div>
        </div>
        {isItineraryCalculable && (
          <div className="BusinessLocator-business-mobile-body-actions">
            <div
              className="BusinessLocator-business-mobile-action"
              onClick={() => {
                showItinerary();
                setExpand(false);
              }}
            >
              <div className="BusinessLocator-business-mobile-action-icon">
                <FontAwesomeIcon icon={faDirections} />
              </div>
              <div className="BusinessLocator-business-mobile-action-label">
                {strings["itinerary"]}
              </div>
            </div>
          </div>
        )}
        <div className="BusinessLocator-business-mobile-body-main">
          <div className="BusinessLocator-business-mobile-openinghours">
            <div className="BusinessLocator-business-mobile-openinghours-title">
              {strings["openninghours"]}
            </div>
            <table className="BusinessLocator-business-mobile-openinghours-timetable">
              <tbody>
                {strings["daysOfWeek"].map((d, i) => {
                  const time = Timetables.find(
                    (t) => t.DayOfWeek === daysOfWeek[i]
                  );
                  return (
                    <tr key={d}>
                      <td>{d}</td>
                      <td className="BusinessLocator-business-mobile-openinghours-day">
                        {time
                          ? getOpenningHours(strings, time.OpeningPeriods)
                          : strings["closed"]}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="BusinessLocator-business-mobile-body-extra">
            {IsOpenDrive && (
              <div className="BusinessLocator-business-mobile-body-extra-icons">
                <div className="BusinessLocator-business-mobile-body-extra-icon">
                  <img alt="pickup" src={`/images/retrait.png`} />
                </div>
                <div className="BusinessLocator-business-mobile-body-extra-label">
                  {strings["takeout"]}
                </div>
              </div>
            )}
            {IsDrive && (
              <div className="BusinessLocator-business-mobile-body-extra-icons">
                <div className="BusinessLocator-business-mobile-body-extra-icon">
                  <img alt="delivery" src={`/images/livraison.png`} />
                </div>
                <div className="BusinessLocator-business-mobile-body-extra-label">
                  {strings["delivery"]}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="BusinessLocator-business-mobile-body-actions">
          <div className="BusinessLocator-business-mobile-action">
            <div className="BusinessLocator-business-mobile-action-icon">
              <FontAwesomeIcon icon={faInfoCircle} />
            </div>
            <div className="BusinessLocator-business-mobile-action-label">
              {strings["seeshop"]}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default addI18nContextConsumer(i18n)(BusinessMobile);
