import "./Panel.scss";

import { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH, faList, faMap } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

import addI18nContextConsumer from "../../i18n/addI18nContextConsumer";
import i18n from "./Panel.i18n.json";

import SearchBar from "../search-bar/SearchBar";
import Business from "../business/Business";
import Filters from "../filters/Filters";

import BusinessesListBuilder from "./utils/BusinessesListBuilder";

import isDisplayedOnMobileScreen from "../../services/mobile";

const FILTERS = [
  // {
  //   type: Filters.FILTERS_TYPE.LIST,
  //   values: [
  //     { value: "Option 1", label: "Option 1" },
  //     { value: "Option 2", label: "Option 2" },
  //     { value: "Option 3", label: "Option 3" },
  //     { value: "Option 4", label: "Option 4" },
  //   ],
  //   property: "property 1",
  // },
  // {
  //   type: Filters.FILTERS_TYPE.SORT,
  //   label: "Filter 2",
  //   property: "property 2",
  // },
  // {
  //   type: Filters.FILTERS_TYPE.BOOLEAN,
  //   label: "Filter 3",
  //   property: "property 3",
  // },
  {
    type: Filters.FILTERS_TYPE.BOOLEAN,
    label: "delivery",
    property: "IsDrive",
  },
  {
    type: Filters.FILTERS_TYPE.BOOLEAN,
    label: "takeout",
    property: "IsOpenDrive",
  },
];

class Panel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterBodyHeight: void 0,
      isFilterVisible: false,
      filtersState: props.filters ? new Array(props.filters.length) : [],
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedBusiness } = this.props;
    if (
      selectedBusiness &&
      (!prevProps.selectedBusiness ||
        selectedBusiness.id !== prevProps.selectedBusiness.id)
    ) {
      const HTMLElement = document.getElementById(
        `BusinessLocator-panel-business-id-${selectedBusiness.id}`
      );
      HTMLElement &&
        HTMLElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
    }
  }

  onLocationSelected = (feature) => {
    const { setSelectedLocation } = this.props;
    setSelectedLocation(feature);
  };

  getFilteredBusinesses = () => {
    const { businesses, location, selectedBusiness, filters } = this.props;
    const { filtersState } = this.state;
    let businessesFiltered = new BusinessesListBuilder(
      businesses,
      location,
      selectedBusiness
    );
    if (location) {
      businessesFiltered
        .addDistanceToFeature()
        .filterFeaturesByDistance()
        .sortFeaturesByDistance();
    } else {
      businessesFiltered.sortFeaturesByName();
    }
    if (selectedBusiness) {
      businessesFiltered.addSelectedBusinessIfNotInTheList();
    }
    return businessesFiltered.checkFilters(filters, filtersState).build();
  };

  render() {
    const {
      map,
      isListVisible,
      setIsListVisible,
      setSelectedBusiness,
      location,
      showItinerary,
      strings,
      selectedBusiness,
      filters,
    } = this.props;
    const { filtersState, isFilterVisible } = this.state;
    const businessesFiltered = this.getFilteredBusinesses();
    return (
      <div className="BusinessLocator-panel">
        <div className="BusinessLocator-panel-title">
          <strong>{strings["find"]}</strong> {strings["a_store"]}
        </div>
        <div className="BusinessLocator-panel-searchbar-container">
          <SearchBar map={map} onLocationSelected={this.onLocationSelected} />
        </div>
        <div className="BusinessLocator-panel-search-info">
          <div className="BusinessLocator-panel-search-info-quickinfo">
            <div className="BusinessLocator-panel-search-info-count">
              {businessesFiltered ? businessesFiltered.features.length : 0}{" "}
              {strings["stores"]}
            </div>
            <div
              className="BusinessLocator-panel-search-info-filter"
              onClick={() => {
                const { isFilterVisible } = this.state;
                this.setState({ isFilterVisible: !isFilterVisible });
              }}
            >
              <FontAwesomeIcon icon={faSlidersH} />
            </div>
          </div>
          <div
            className="BusinessLocator-panel-search-info-quickinfo"
            onClick={() => {
              setIsListVisible(!isListVisible);
            }}
          >
            <div className="BusinessLocator-panel-search-info-btn-list">
              {isListVisible ? strings["map"] : strings["list"]}
            </div>
            <div className="BusinessLocator-panel-search-info-filter">
              <FontAwesomeIcon icon={isListVisible ? faMap : faList} />
            </div>
          </div>
        </div>
        {map && (
          <Filters
            filters={filters}
            filtersState={filtersState}
            setFiltersState={(filtersState) => this.setState({ filtersState })}
            isOpen={isFilterVisible}
          />
        )}
        <div
          className={classNames("BusinessLocator-panel-business-list", {
            "BusinessLocator-panel-business-list--visible": isListVisible,
          })}
        >
          {businessesFiltered &&
            businessesFiltered.features &&
            businessesFiltered.features.map((business) => (
              <div
                key={business.id}
                id={`BusinessLocator-panel-business-id-${business.id}`}
                className={classNames({
                  "BusinessLocator-panel-business--selected":
                    selectedBusiness && selectedBusiness.id === business.id,
                })}
              >
                <Business
                  id={business.id}
                  business={business.properties}
                  setSelectedBusiness={() => {
                    setSelectedBusiness(business);
                    setIsListVisible(false);
                  }}
                  isItineraryCalculable={
                    !!location || isDisplayedOnMobileScreen()
                  }
                  showItinerary={() => showItinerary(business)}
                />
              </div>
            ))}
        </div>
      </div>
    );
  }
}

Panel.defaultProps = {
  filters: FILTERS,
};

export default addI18nContextConsumer(i18n)(Panel);
