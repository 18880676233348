export const token = process.env.REACT_APP_JAWG_STYLE_ACCESS_TOKEN;
export const BLID =
  /*process.env.REACT_APP_JAWG_BLID ||*/ "4d2b1ca7-2051-4a98-8a29-5eee77cfe712";
export const apiUrl = process.env.REACT_APP_JAWG_API_URL;
export const mapUrl = apiUrl
  ? `${apiUrl}/styles/`
  : "https://api.jawg.io/styles/";
export const geocodingUrl = apiUrl
  ? `${apiUrl}/places/v1/`
  : "https://api.jawg.io/places/v1/";
export const routingUrl = apiUrl
  ? `${apiUrl}/routing/route/v1/`
  : "https://api.jawg.io/routing/route/v1/";
export const MODE_ROUTING = { CAR: "car", PEDESTRIAN: "walk" };
export const REACT_APP_JAWG_PLACES_ACCESS_TOKEN =
  /*process.env.REACT_APP_JAWG_PLACES_ACCESS_TOKEN ||*/
  "obJKcxd4bdbNsEE1KvrusZ3xC6L6mjElysZ7XOKBD90uDtZ2A4BPHxhQuHGzfi8X";
export const TMP_TO_REMOVE_BL_API_TOKEN =
  /*process.env.REACT_APP_JAWG_TMP_TO_REMOVE_BL_API_TOKEN ||*/
  "EfWe2ohL5W5H5zfDC90x2roztn5daoIAVyYd2HgVaYM4kJW2hb282X8R1bZ6EiAV";
export const TMP_TO_REMOVE_BL_API_URL =
  /*process.env.REACT_APP_JAWG_TMP_TO_REMOVE_BL_API_URL ||*/
  "https://api.r7.jawg.io";
export const BL_LOCATION_SOURCE_NAME = "bl-location";
export const BL_LOCATION_LAYER_NAME = "bl-location";
export const BL_MARKER_DEFAULT = "bl-marker-default";
export const BL_MARKER_SIMPLE = "bl-marker-simple";
export const BL_MARKER_FOCUS_SUFFIX = ":focus";

export function getRoutingUrl(mode, from, to) {
  return `${routingUrl}${mode}/${from.lng},${from.lat};${to.lng},${to.lat}?access-token=${token}`;
}
export function getStyleUrl(style) {
  return `${TMP_TO_REMOVE_BL_API_URL}/businesslocators/${BLID}/versions/latest/style?access-token=${TMP_TO_REMOVE_BL_API_TOKEN}&basestyle=${style}`;
}
