export default function MarkerFrom({
  markerColor,
  strokeMarkerColor,
  middleMarkerColor,
}) {
  return `
  <div>
    <div
      style="background-color: ${markerColor};
        border-color: ${strokeMarkerColor};
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-width: 2px;
        border-style: solid;"
    >
      <div
        style="background-color: ${middleMarkerColor};
          width: 12px;
          height: 12px;
          border-radius: 50%;"
      />
    </div>
  </div>
`;
}
