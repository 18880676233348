import "./Footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebook,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import addI18nContextConsumer from "../../../i18n/addI18nContextConsumer";
import i18n from "./Footer.i18n.json";

function Footer({ strings }) {
  return (
    <footer className="footer py-4">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 text-lg-left">
            Copyright © Store Locator 2021
          </div>
          <div className="col-lg-4 my-3 my-lg-0">
            <a className="btn btn-dark btn-social mx-2" href="#!">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a className="btn btn-dark btn-social mx-2" href="#!">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a className="btn btn-dark btn-social mx-2" href="#!">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
          <div className="col-lg-4 text-lg-right">
            <a className="mr-3" href="#!">
              {strings["policy"]}
            </a>
            <a href="#!">{strings["terms"]}</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default addI18nContextConsumer(i18n)(Footer);
