import "./Map.scss";

import React, { useRef } from "react";
import JawgMap from "@jawg/react-jawg-map";

import addI18nContextConsumer from "../../i18n/addI18nContextConsumer";
import i18n from "./Map.i18n.json";

import { getStyleUrl } from "../../conf/conf";
import { mapInit } from "../map/utils";

function Map({
  strings,
  onInit,
  callbackFeatureClicked,
  getStyleComponent,
  getMapActive,
  setMapActive,
}) {
  const mapContainer = useRef();
  const mapMessage = useRef();

  function init(map) {
    setTimeout(() => {
      mapInit(
        map._map,
        callbackFeatureClicked,
        mapContainer.current,
        mapMessage.current,
        getStyleComponent,
        getMapActive,
        setMapActive
      );
      onInit(map);
    });
  }

  return (
    <div className="BusinessLocator-map" ref={mapContainer}>
      <div className="BusinessLocator-map-message" ref={mapMessage}>
        {strings["map-message"]}
      </div>
      <JawgMap
        disableAttribution={true}
        mapStyle={getStyleUrl("jawg-sunny")}
        onInit={init}
        scale={true}
        zoom={8}
        hash={false}
      />
    </div>
  );
}

export default addI18nContextConsumer(i18n)(Map);
