// Based on ygoe response on mapbox gl js issue #2618 (https://github.com/mapbox/mapbox-gl-js/issues/2618)

export default function initMapMobile(
  map,
  mapContainer,
  mapMessage,
  getMapActive,
  setMapActive
) {
  map.doubleClickZoom.disable();
  map.scrollZoom.disable();
  map.touchZoomRotate.disable();
  map.touchPitch.disable();
  map.dragPan.disable();
  map.dragRotate.disable();
  map.touchZoomRotate.disableRotation();

  // Create a message that instructs the user to double-click/tap to activate the map control
  setMapActive(false);
  let mapMessageTimeout;
  const showMessage = () => {
    mapMessage.style.opacity = "0.999"; // opacity 1 makes it invisible!
    clearTimeout(mapMessageTimeout);
    mapMessageTimeout = setTimeout(() => {
      mapMessage.style.opacity = "0";
    }, 1500);
  };
  map.on("touchmove", (e) => {
    if (
      e.originalEvent.touches &&
      e.originalEvent.touches.length === 1 &&
      !getMapActive()
    )
      showMessage();
  });
  map.on("wheel", (e) => {
    if (!getMapActive()) showMessage();
  });
  let isMouseButtonPressed = false;
  map.on("mousedown", (e) => {
    if (e.originalEvent.button === 0) isMouseButtonPressed = true;
  });
  map.on("mouseup", (e) => {
    if (e.originalEvent.button === 0) isMouseButtonPressed = false;
  });
  // The mouseleave event is not supported with on() (mapbox/mapbox-gl-js#10594)
  mapContainer.addEventListener("mouseleave", (e) => {
    isMouseButtonPressed = false;
  });
  map.on("mousemove", (e) => {
    if (!getMapActive() && isMouseButtonPressed) showMessage();
  });

  // Activate or deactivate the map control with a double click/tap to prevent unintended
  // scrolling, panning or zooming on the usual page scroll interactions.
  // The dblclick event is massively broken in Firefox (fires twice, for mouse and touch)
  // and Chrome (not at all), so we have to do that manually.
  let lastClickTime;
  let timeout;
  map.on("click", (event) => {
    if (event.originalEvent.detail > 2) return; // Ignore triple-click in Firefox (no event in Chrome)
    const now = Date.now();
    if (lastClickTime >= now - 500) {
      //console.log("double click", event);
      clearTimeout(timeout);
      setMapActive(!getMapActive());
      if (getMapActive()) {
        map.scrollZoom.enable();
        map.touchZoomRotate.enable();
        map.dragPan.enable();
        mapContainer.style.outline = "2px solid limegreen"; // visualisation for the activated map
        clearTimeout(mapMessageTimeout);
        mapMessage.style.opacity = "0";
        // Fix wrong mouse cursor (mapbox/mapbox-gl-js#10593)
        mapContainer.querySelector(
          "div.mapboxgl-canvas-container.mapboxgl-interactive"
        ).style.cursor = "";
      } else {
        map.scrollZoom.disable();
        map.touchZoomRotate.disable();
        map.dragPan.disable();
        mapContainer.style.outline = "none";
        // Fix wrong mouse cursor (mapbox/mapbox-gl-js#10593)
        mapContainer.querySelector(
          "div.mapboxgl-canvas-container.mapboxgl-interactive"
        ).style.cursor = "default";
      }
      lastClickTime = undefined;
    } else {
      lastClickTime = now;
      timeout = setTimeout(() => {
        if (!getMapActive()) showMessage();
      }, 500);
    }
  });
}
