import getMarkerFrom from "./marker/markerFrom";
import getMarkerTo from "./marker/markerTo";
import initMapMobile from "./initMapMobile";
import isDisplayedOnMobileScreen from "../../services/mobile";

import {
  BL_MARKER_SIMPLE,
  BL_MARKER_DEFAULT,
  BL_MARKER_FOCUS_SUFFIX,
  BL_LOCATION_LAYER_NAME,
} from "../../conf/conf";

////////////////////////////////////////

export function buildIconImageExpression(featureId, styleComponent) {
  return [
    "step",
    ["zoom"],
    [
      "concat",
      styleComponent.simple.enabled ? BL_MARKER_SIMPLE : BL_MARKER_DEFAULT,
      [
        "match",
        ["id"],
        featureId,
        styleComponent.focus ? BL_MARKER_FOCUS_SUFFIX : "",
        "",
      ],
    ],
    styleComponent.simple.zoom,
    [
      "concat",
      BL_MARKER_DEFAULT,
      [
        "match",
        ["id"],
        featureId,
        styleComponent.focus ? BL_MARKER_FOCUS_SUFFIX : "",
        "",
      ],
    ],
  ];
}

function highlightFeature(feature, map, styleComponent) {
  //Highlight on map
  const featureId = Number.parseInt(feature.id);
  let zoom = Math.max(map.getZoom(), 12);
  map.flyTo({
    center: feature.geometry.coordinates,
    zoom: zoom,
  });
  map.setLayoutProperty(
    BL_LOCATION_LAYER_NAME,
    "icon-image",
    buildIconImageExpression(featureId, styleComponent)
  );
}

export async function mapInit(
  map,
  callbackFeatureClicked,
  mapContainer,
  mapMessage,
  getStyleComponent,
  getMapActive,
  setMapActive
) {
  // Listen for when the map is ready

  map.once("load", () => {
    // Center the map on the coordinates of any clicked bl-location from the 'bl-location' layer.
    map.on("click", BL_LOCATION_LAYER_NAME, function (e) {
      if (!getMapActive()) {
        return;
      }
      const zoom = Math.max(map.getZoom(), 12);
      map.flyTo({
        center: e.features[0].geometry.coordinates,
        zoom: zoom,
      });
    });

    // Hightlight the store on the map and on the list
    map.on("click", BL_LOCATION_LAYER_NAME, function (e) {
      if (!getMapActive()) {
        return;
      }
      const styleComponent = getStyleComponent();
      highlightFeature(e.features[0], map, styleComponent);
      callbackFeatureClicked && callbackFeatureClicked(e.features[0].id);
    });

    // Change the cursor to a pointer when the it enters a feature in the 'bl-location' layer.
    map.on("mouseenter", BL_LOCATION_LAYER_NAME, function () {
      map.getCanvas().style.cursor = "pointer";
    });

    // Change it back to a pointer when it leaves.
    map.on("mouseleave", BL_LOCATION_LAYER_NAME, function () {
      map.getCanvas().style.cursor = "";
    });

    // Let the stores'list interact with the map
    // linkStoresListToMap(stores.features);
  });

  if (isDisplayedOnMobileScreen()) {
    initMapMobile(map, mapContainer, mapMessage, getMapActive, setMapActive);
  }
}

////////////////////////////////////////

const ZOOM_FOR_SMALL_POI = 18;
const ZOOM_FOR_POI = 14;

export const EVENTS = { GO_TO_FEATURE: "go-to-feature" };

export const MARKER_TYPE = { FROM: "FROM", TO: "TO" };

export const drawAndGoToFeatureLocation = (map, feature) => {
  goToFeatureLocation(map, feature);
  return {
    markerId: drawMarker(map, feature),
    polygonId: drawPolygon(map, feature),
  };
};

export const goToFeatureLocation = (map, feature) => {
  if (map) {
    map.jumpTo({
      center: feature.geometry.coordinates,
      zoom: getBestZoom(feature),
    });
    map.fire(EVENTS.GO_TO_FEATURE);
  }
};

export const getBestZoom = (feature) => {
  const bbox = feature.bbox;
  if (!bbox) {
    return ["address", "venue", "street"].indexOf(feature.properties.layer) > -1
      ? ZOOM_FOR_SMALL_POI
      : ZOOM_FOR_POI;
  }
  const abs = Math.abs(bbox[2] - bbox[0]) * Math.abs(bbox[3] - bbox[1]);
  return abs !== 0 ? 8.5 - Math.log10(abs) : 8.5;
};

export const drawMarker = (
  map,
  feature,
  markerType = MARKER_TYPE.TO,
  {
    strokeMarkerColor = "#feefcd",
    markerColor = "#fdc444",
    middleMarkerColor = "#ffffff",
    pulseEffectMarkerColor = "#3678c2",
  } = {}
) => {
  const markerCreator =
    markerType === MARKER_TYPE.FROM ? getMarkerFrom : getMarkerTo;
  const customMarker = map.addMarker(
    feature.geometry.coordinates,
    stringToHtmlElement(
      markerCreator({
        markerColor,
        middleMarkerColor,
        pulseEffectMarkerColor,
        strokeMarkerColor,
      })
    )
  );
  return customMarker;
};

export const drawPolygon = (map, feature) => {
  return map.addPolygonOfFeature(feature);
};

export const stringToHtmlElement = (html) => {
  const template = document.createElement("template");
  template.innerHTML = html.trim();
  return template.content.firstChild;
};

export const drawAndGoToRouteLocation = (map, route, fitOption) => {
  const options = Object.assign({}, { padding: 60 }, fitOption);
  const id = map.addRoute(route, {
    routeStrokeColor: "#feefcd",
    routeColor: "#fdc444",
  });
  route && map.fitBounds(getBbox(route), options);
  map.fire(EVENTS.GO_TO_FEATURE);
  return id;
};

export const getBbox = (routeCoordinates) => {
  let minLeft = void 0;
  let minBottom = void 0;
  let maxRight = void 0;
  let maxTop = void 0;
  routeCoordinates.forEach((coordinates) => {
    if (minLeft === void 0 || coordinates[0] < minLeft) {
      minLeft = coordinates[0];
    }
    if (minBottom === void 0 || coordinates[1] < minBottom) {
      minBottom = coordinates[1];
    }
    if (maxRight === void 0 || coordinates[0] > maxRight) {
      maxRight = coordinates[0];
    }
    if (maxTop === void 0 || coordinates[1] > maxTop) {
      maxTop = coordinates[1];
    }
  });
  return [minLeft, minBottom, maxRight, maxTop];
};
