import distance from "@turf/distance";
import Filters from "../../filters/Filters";

export default class businessesListBuilder {
  constructor(businessesGeoJSON, location, selectedBusiness) {
    this._businessesGeoJSON = businessesGeoJSON;
    this._location = location;
    this._selectedBusiness = selectedBusiness;
  }

  _setFeatures = (features) => {
    if (this._businessesGeoJSON && typeof features !== "undefined") {
      this._businessesGeoJSON = { ...this._businessesGeoJSON, features };
    }
  };

  addSelectedBusinessIfNotInTheList = () => {
    if (
      this._location &&
      this._selectedBusiness &&
      !this._businessesGeoJSON.features.find(
        (f) => f.id === this._selectedBusiness.id
      )
    ) {
      this._setFeatures([
        this.addDistanceToFeature(this._selectedBusiness),
        ...this._businessesGeoJSON.features,
      ]);
    }
    return this;
  };

  addDistanceToFeature = () => {
    if (this._businessesGeoJSON && this._location) {
      const features = this._businessesGeoJSON.features;
      this._setFeatures(
        features.map((feature) => {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              DistanceFromRef: distance(
                feature.geometry.coordinates,
                this._location.geometry.coordinates
              ),
            },
          };
        })
      );
    }
    return this;
  };

  filterFeaturesByDistance = () => {
    if (!this._businessesGeoJSON) {
      return this;
    }
    const features = this._businessesGeoJSON.features;
    this._setFeatures(
      features.filter(function (feature) {
        return (
          !feature.properties.DistanceFromRef ||
          feature.properties.DistanceFromRef <= 150
        );
      })
    );
    return this;
  };

  sortFeaturesByDistance = () => {
    if (!this._businessesGeoJSON) {
      return this;
    }
    const features = this._businessesGeoJSON.features;
    this._setFeatures(
      features.sort(function (sA, sB) {
        return sA.properties.DistanceFromRef - sB.properties.DistanceFromRef;
      })
    );
    return this;
  };

  sortFeaturesByName = () => {
    if (!this._businessesGeoJSON) {
      return this;
    }
    const features = this._businessesGeoJSON.features;
    this._setFeatures(
      features.sort(function (ba, bb) {
        return ba.properties.Name.localeCompare(bb.properties.Name);
      })
    );
    return this;
  };

  checkFilters = (filters, filtersState) => {
    if (!filtersState || !this._businessesGeoJSON) {
      return this;
    }
    filtersState.forEach((filterState, index) => {
      if (typeof filterState === "undefined") {
        return;
      }
      const features = this._businessesGeoJSON.features;
      const filter = filters[index];
      switch (filter.type) {
        case Filters.FILTERS_TYPE.SORT:
          if (!filterState || filterState === "NONE") {
            return;
          } else {
            this._setFeatures(
              features.sort(function (ba, bb) {
                const propertyofBA = ba.properties[filter.property];
                const propertyofBB = bb.properties[filter.property];
                if (filterState === "ASC") {
                  return propertyofBA - propertyofBB;
                } else {
                  return propertyofBB - propertyofBA;
                }
              })
            );
          }
          break;
        case Filters.FILTERS_TYPE.LIST:
        case Filters.FILTERS_TYPE.BOOLEAN:
          this._setFeatures(
            features.filter(function (feature) {
              return feature.properties[filter.property] === filterState;
            })
          );
          break;
        default:
          break;
      }
    });
    return this;
  };

  build = () => {
    return this._businessesGeoJSON;
  };
}
