import "./Filters.scss";

import { Component, createRef } from "react";
import classNames from "classnames";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";

import addI18nContextConsumer from "../../i18n/addI18nContextConsumer";
import i18n from "./Filters.i18n.json";

const FILTERS_TYPE = {
  LIST: "LIST",
  SORT: "SORT",
  BOOLEAN: "BOOLEAN",
};

class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersHeight: void 0,
    };
    this.filtersRef = createRef();
  }

  componentDidMount() {
    const filtersHeight = this.filtersRef.current.clientHeight;
    this.setState({
      filtersHeight,
    });
  }

  renderFilter = (filter, index) => {
    const { filtersState, setFiltersState, strings } = this.props;
    const value = filtersState[index];
    if (!filter) {
      return;
    }
    switch (filter.type) {
      case FILTERS_TYPE.LIST:
        return (
          <div
            className={classNames(
              "BusinessLocator-panel-filter",
              "BusinessLocator-panel-filter-select"
            )}
            key={index}
          >
            <select
              name=""
              onChange={({ value }) => {
                const { filtersState } = this.props;
                const newFiltersState = [...filtersState];
                newFiltersState.splice(index, 1, value);
                setFiltersState(newFiltersState);
              }}
            >
              {filter.values.map((option) => (
                <option key={option.label} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        );
      case FILTERS_TYPE.SORT:
        return (
          <div
            className={classNames(
              "BusinessLocator-panel-filter",
              "BusinessLocator-panel-filter-sort",
              {
                "BusinessLocator-panel-filter--activated":
                  value && value !== "NONE",
              }
            )}
            onClick={() => {
              const { filtersState } = this.props;
              let newValue = "";
              switch (filtersState[index]) {
                case "ASC":
                  newValue = "DES";
                  break;
                case "DES":
                  newValue = "NONE";
                  break;
                default:
                case "NONE":
                  newValue = "ASC";
                  break;
              }

              const newFiltersState = [...filtersState];
              newFiltersState.splice(index, 1, newValue);
              setFiltersState(newFiltersState);
            }}
          >
            {filter.label}{" "}
            <FontAwesomeIcon
              icon={
                value === "ASC"
                  ? faSortUp
                  : value === "DES"
                  ? faSortDown
                  : faSort
              }
            />
          </div>
        );
      case FILTERS_TYPE.BOOLEAN:
        return (
          <div
            className={classNames(
              "BusinessLocator-panel-filter",
              "BusinessLocator-panel-filter-check",
              { "BusinessLocator-panel-filter--activated": value }
            )}
            onClick={() => {
              const { filtersState } = this.props;
              const newFiltersState = [...filtersState];
              newFiltersState.splice(index, 1, !filtersState[index]);
              setFiltersState(newFiltersState);
            }}
          >
            <input type="checkbox" checked={value} />{" "}
            {filter.label && (strings[filter.label] || filter.label)}
          </div>
        );
      default:
        break;
    }
  };

  render() {
    const { isOpen, filters } = this.props;
    const { filtersHeight } = this.state;

    let filtersMaxHeight = void 0;
    if (filtersHeight) {
      if (isOpen) {
        filtersMaxHeight = filtersHeight;
      } else {
        filtersMaxHeight = 0;
      }
    }
    return (
      <div
        ref={this.filtersRef}
        className={classNames("BusinessLocator-panel-filters", {
          "BusinessLocator-panel-filters--opened": isOpen,
        })}
        style={
          Number.isFinite(filtersMaxHeight)
            ? {
                maxHeight: filtersMaxHeight + (isOpen ? 30 : 0),
              }
            : {}
        }
      >
        {filters &&
          filters.map((filter, index) => this.renderFilter(filter, index))}
      </div>
    );
  }
}

const FiltersWrapper = addI18nContextConsumer(i18n)(Filters);

FiltersWrapper.FILTERS_TYPE = FILTERS_TYPE;

export default FiltersWrapper;
