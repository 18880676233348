import "./App.css";

import BusinessLocator from "./components/businesslocator/BusinessLocator";
import Header from "./components/mocks/header/Header";
import Footer from "./components/mocks/footer/Footer";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
      <Header />
      <div className="BusinessLocator">
        <BusinessLocator />
      </div>
      <Footer />
    </div>
  );
}

export default App;
